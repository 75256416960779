@import url('https://fonts.googleapis.com/css2?family=Barlow+Semi+Condensed:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@700&display=swap');
@import url('https://fonts.cdnfonts.com/css/avenir');
@import url('https://fonts.googleapis.com/css2?family=Gothic+A1:wght@100;200;300;400;500;600;700;900&display=swap');
@import url('https://fonts.cdnfonts.com/css/proxima-nova-2');
@import url('https://fonts.googleapis.com/css2?family=Balsamiq+Sans:wght@400;700&family=Gothic+A1:wght@100;200;300;400;500;600;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Balsamiq+Sans:wght@400;700&family=Gothic+A1:wght@100;200;300;400;500;600;700;900&family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Luckiest+Guy&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Luckiest+Guy&family=Manrope:wght@200..800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Luckiest+Guy&family=Manrope:wght@200..800&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
	@font-face {
		font-family: Museo;
		font-weight: 700;
		src: url(./assets/fonts/Museo700.otf) format('otf');
	}
	@font-face {
		font-family: Museo;
		font-weight: 500;
		src: url(./assets/fonts/Museo500.otf) format('otf');
	}
	@font-face {
		font-family: 'Gilroy';
		src: url('./assets/fonts/Gilroy-Black.ttf') format('truetype');
		font-weight: 900;
		font-style: normal;
	}
	@font-face {
		font-family: 'Gilroy';
		src: url('./assets/fonts/Gilroy-BlackItalic.ttf') format('truetype');
		font-weight: 900;
		font-style: italic;
	}
	@font-face {
		font-family: 'Gilroy';
		src: url('./assets/fonts/Gilroy-Bold.ttf') format('truetype');
		font-weight: 700;
		font-style: normal;
	}
	@font-face {
		font-family: 'Gilroy';
		src: url('./assets/fonts/Gilroy-BoldItalic.ttf') format('truetype');
		font-weight: 700;
		font-style: italic;
	}
	@font-face {
		font-family: 'Gilroy';
		src: url('./assets/fonts/Gilroy-ExtraBold.ttf') format('truetype');
		font-weight: 800;
		font-style: normal;
	}
	@font-face {
		font-family: 'Gilroy';
		src: url('./assets/fonts/Gilroy-ExtraBoldItalic.ttf') format('truetype');
		font-weight: 800;
		font-style: italic;
	}
	@font-face {
		font-family: 'Gilroy';
		src: url('./assets/fonts/Gilroy-Heavy.ttf') format('truetype');
		font-weight: 700;
		font-style: normal;
	}
	@font-face {
		font-family: 'Gilroy';
		src: url('./assets/fonts/Gilroy-HeavyItalic.ttf') format('truetype');
		font-weight: 700;
		font-style: italic;
	}
	@font-face {
		font-family: 'Gilroy';
		src: url('./assets/fonts/Gilroy-Light.ttf') format('truetype');
		font-weight: 300;
		font-style: normal;
	}
	@font-face {
		font-family: 'Gilroy';
		src: url('./assets/fonts/Gilroy-LightItalic.ttf') format('truetype');
		font-weight: 300;
		font-style: italic;
	}
	@font-face {
		font-family: 'Gilroy';
		src: url('./assets/fonts/Gilroy-Medium.ttf') format('truetype');
		font-weight: 500;
		font-style: normal;
	}
	@font-face {
		font-family: 'Gilroy';
		src: url('./assets/fonts/Gilroy-MediumItalic.ttf') format('truetype');
		font-weight: 500;
		font-style: italic;
	}
	@font-face {
		font-family: 'Gilroy';
		src: url('./assets/fonts/Gilroy-Regular.ttf') format('truetype');
		font-weight: 400;
		font-style: normal;
	}
	@font-face {
		font-family: 'Gilroy';
		src: url('./assets/fonts/Gilroy-RegularItalic.ttf') format('truetype');
		font-weight: 400;
		font-style: italic;
	}
	@font-face {
		font-family: 'Gilroy';
		src: url('./assets/fonts/Gilroy-SemiBold.ttf') format('truetype');
		font-weight: 600;
		font-style: normal;
	}
	@font-face {
		font-family: 'Gilroy';
		src: url('./assets/fonts/Gilroy-SemiBoldItalic.ttf') format('truetype');
		font-weight: 600;
		font-style: italic;
	}
	@font-face {
		font-family: 'Gilroy';
		src: url('./assets/fonts/Gilroy-Thin.ttf') format('truetype');
		font-weight: 200;
		font-style: normal;
	}
	@font-face {
		font-family: 'Gilroy';
		src: url('./assets/fonts/Gilroy-ThinItalic.ttf') format('truetype');
		font-weight: 200;
		font-style: italic;
	}
	@font-face {
		font-family: 'Gilroy';
		src: url('./assets/fonts/Gilroy-UltraLight.ttf') format('truetype');
		font-weight: 100;
		font-style: normal;
	}
	@font-face {
		font-family: 'Gilroy';
		src: url('./assets/fonts/Gilroy-UltraLightItalic.ttf') format('truetype');
		font-weight: 100;
		font-style: italic;
	}
}

@layer base {
	input[type='number']::-webkit-inner-spin-button,
	input[type='number']::-webkit-outer-spin-button {
		-webkit-appearance: none;
	}
}

*,
*::after,
*::before {
	box-sizing: border-box;
	font-family: 'Inter', sans-serif;
}

@media (max-width: 840px) {
	*,
	*::after,
	*::before {
		font-family: 'Manrope', sans-serif;
	}
}

body {
	background-color: #1a1c1e;
	overflow-x: hidden;
}

.text-shadow-md {
	text-shadow:
		0px 1px 3px rgba(0, 0, 0, 0.15),
		0px 1px 2px rgba(0, 0, 0, 0.3);
}

.text-shadow-sm {
	text-shadow:
		0px 1px 2px rgba(0, 0, 0, 0.1),
		0px 1px 1px rgba(0, 0, 0, 0.2);
}

.text-shadow-none {
	text-shadow: none;
}

::-webkit-scrollbar {
	width: 0.3em;
	box-sizing: content-box;
}

::-webkit-scrollbar-track {
	background: rgba(255, 255, 255, 0.5);
	border-radius: 100vh;
}

::-webkit-scrollbar-thumb {
	background: rgba(63, 167, 233, 0.7);
	border-radius: 100vh;
}

@supports (scrollbar-color: white #0f2430) {
	* {
		scrollbar-color: white #0f2430;
		scrollbar-width: 0.4em;
	}
}

.no-scrollbar {
	-ms-overflow-style: none; /* Internet Explorer and Edge */
	scrollbar-width: none; /* Firefox */
}

.no-scrollbar::-webkit-scrollbar {
	display: none; /* Chrome, Safari, Opera */
}

.text-gradient {
	background: linear-gradient(180deg, rgba(255, 255, 255, 0.63) 0%, rgba(255, 255, 255, 0) 103.64%);
	-webkit-background-clip: text;
	background-clip: text;
	-webkit-text-fill-color: transparent;
	text-fill-color: transparent;
}

.absolute-center {
	@apply absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%];
}

.clip-shape {
	width: 200px;
	height: 200px;
	background-color: red;
	clip-path: polygon(50% 0%, 100% 40%, 80% 100%, 20% 100%, 0% 40%);
}

.size-container {
	container-type: size;
}

@keyframes animate-breath {
	0% {
		transform: scale(1);
	}
	50% {
		transform: scale(1.1);
	}
	100% {
		transform: scale(1);
	}
}

.animate-breath {
	animation: animate-breath 4s infinite;
}

:root {
	--rsbs-bg: linear-gradient(331.41deg, #236cf4 6.78%, #33f0a7 204.87%); /* bg color bottom sheet lib */
	--rsbs-overlay-rounded: 24px; /* Rounder corners */
	--rsbs-handle-bg: rgba(255, 255, 255, 0.7);
}

/* component-specific.css */
.standard-bottom-sheet-color {
	--rsbs-bg: #16265e;
	--rsbs-handle-bg: rgba(255, 255, 255, 0.7);
}

/* google recaptcha hide*/
.grecaptcha-badge {
	visibility: hidden;
}
