.holo-gradient::before,
.holo-gradient::after {
	content: '';
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	z-index: 1;
	pointer-events: none;
	mix-blend-mode: color-dodge;
	opacity: 0.4;
}

/* Texture overlay */
.holo-gradient .texture-overlay::before {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: url('https://res.cloudinary.com/simey/image/upload/Dev/PokemonCards/illusion.webp');
	background-size: cover;
	background-repeat: no-repeat;
	opacity: 0.15; /* Adjust for subtlety */
	mix-blend-mode: overlay; /* Adds depth to the background color */
}

/* Light Blue Rarity - Simple Gradient */
.holo-lightBlue::before {
	background: linear-gradient(120deg, #7ec8e3, transparent 50%);
	background-size: 200% 200%;
	animation: holo-move-lightblue 10s ease-in-out infinite;
}
/* Light Blue Rarity - Sparkle Effect */
.holo-lightBlue::after {
	background-size: cover;
	opacity: 0.25;
	z-index: 2;
}
/* Blue Rarity - Slightly More Complex Gradient */
.holo-blue::before {
	background: linear-gradient(130deg, #3a7bd5, transparent 50%);
	background-size: 300% 300%;
	animation: holo-move-blue 12s ease-in-out infinite;
}
/* Blue Rarity - Sparkle Effect */
.holo-blue::after {
	background-size: cover;
	opacity: 0.25;
	z-index: 2;
}

/* Purple Rarity - Multicolor Gradient */
.holo-purple::before {
	background: radial-gradient(circle at center, rgba(128, 0, 128, 0.6), rgba(75, 0, 130, 0.8), rgba(0, 0, 255, 0.4));
	background-size: 400% 400%;
	animation: holo-move-fog 20s ease infinite;
	opacity: 0.6;
}
/* Purple Rarity - Sparkle Effect */
.holo-purple::after {
	background-size: cover;
	opacity: 0.3;
	z-index: 2;
}
@keyframes holo-move-fog {
	0% {
		background-position: 0% 0%;
	}
	100% {
		background-position: 100% 100%;
	}
}

/* Pink Rarity - Sparkle Effect and Holo */
.holo-pink::before {
	background: linear-gradient(115deg, transparent 0%, rgb(0, 231, 255) 30%, rgb(255, 0, 231) 70%, transparent 100%);
	background-size: 300% 300%;
	animation: holo-gradient-move 15s ease infinite;
}
.holo-pink::after {
	background-size: cover;
	opacity: 0.3;
	z-index: 2;
}

/* Red Rarity - Sparkling and Holo Effect */
.holo-red::before {
	background: linear-gradient(115deg, transparent 0%, rgb(255, 0, 123) 30%, rgba(255, 0, 4, 0.7) 70%, transparent 100%);
	background-size: 400% 400%;
	animation: holo-move-red 14s ease-in-out infinite;
}
.holo-red::after {
	background-size: cover;
	opacity: 0.5;
	z-index: 2;
}

/* Gold Rarity - Softer Shine and Reduced Brightness */
.holo-gold::before {
	background-size: 400% 400%; /* Increased size for more visible movement */
	animation: holo-move-gold 5s ease-in-out infinite; /* Slightly faster for more dynamic effect */
}
.holo-gold::after {
	background-size: cover;
	opacity: 0.3; /* Lower sparkle opacity */
	z-index: 3;
}

/* Keyframes */
@keyframes holo-move-lightblue {
	0% {
		background-position: 0% 0%;
	}
	100% {
		background-position: 100% 100%;
	}
}
@keyframes holo-move-blue {
	0% {
		background-position: 0% 0%;
	}
	100% {
		background-position: 100% 100%;
	}
}
@keyframes holo-move-purple {
	0% {
		background-position: 0% 0%;
	}
	100% {
		background-position: 100% 100%;
	}
}
@keyframes holo-gradient-move {
	0%,
	100% {
		background-position: 0% 0%;
	}
	50% {
		background-position: 100% 100%;
	}
}
@keyframes holo-move-red {
	0% {
		background-position: 0% 0%;
	}
	100% {
		background-position: 100% 100%;
	}
}
@keyframes holo-move-gold {
	0% {
		background-position: 0% 0%;
	}
	100% {
		background-position: 100% 100%;
	}
}

.product-image {
	position: relative;
	z-index: 2;
	transform: translateZ(20px); /* Brings the image forward and slightly enlarges it */
	transition: transform 0.3s ease; /* Smooth transition */
	filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.3));
}

.size-container:hover .product-image {
	transform: translateZ(40px); /* Increase hover effect */
}
