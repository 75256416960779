/* loadSpinner.module.css */
.loader {
	width: var(--loader-size);
	height: var(--loader-size);
	border-radius: 50%;
	display: inline-block;
	position: relative;
	border: 3px solid;
	border-color: #fff #fff transparent;
	box-sizing: border-box;
	animation: rotation 1s linear infinite;
}

.loader::after {
	content: '';
	box-sizing: border-box;
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	margin: auto;
	border: 3px solid;
	border-color: transparent #2981e9 #2981e9;
	width: calc(var(--loader-size) / 2);
	height: calc(var(--loader-size) / 2);
	border-radius: 50%;
	animation: rotationBack 0.5s linear infinite;
	transform-origin: center center;
}

@keyframes rotation {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

@keyframes rotationBack {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(-360deg);
	}
}
