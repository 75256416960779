.separator {
	display: flex;
	align-items: center;
	text-align: center;
}

.separator::before,
.separator::after {
	content: '';
	flex: 1;
	border-bottom: 1px solid currentColor;
	border-color: var(--separator-color);
	width: var(--separator-width, auto);
}

.separator:not(:empty)::before {
	margin-right: 1em;
}

.separator:not(:empty)::after {
	margin-left: 1em;
}
