.slick-dots {
	position: absolute;
	bottom: 25px;
}

/* Target all pagination dots */
.slick-dots li button:before {
	content: none;
}

.slick-dots li {
	margin: 0 6px;
}

.slick-track {
	display: flex;
	align-items: center;
}

.slick-dots li button,
.slick-dots li {
	opacity: 1; /* Ensure visibility */
	border-radius: 20px;
	height: 12px;
	width: 12px;
	transition: width 0.5s;
}

.slick-dots li button {
	background-color: rgba(0, 0, 0, 0.2);
}

/* Target the active dot */
.slick-dots li.slick-active button:before {
	content: none;
}

.slick-dots li.slick-active button,
.slick-dots li.slick-active {
	background: rgb(41 129 233);
	width: 30px;
	content: none;
}
